.gameArea{
    position: relative;
    margin: 0 auto 50px auto;
    width: 600px;
    height: 600px;
    border: var(--border);
}
.snakeDot {
    position: absolute;
    width: 2%;
    height: 2%;
    background-color: var(--accent);
    border: 2x solid black !important;
    z-index: 2;
}
.snakeFood {
    position: absolute;
    width: 2%;
    height: 2%;
    background-color: var(--orange);
    border-radius: 2px;
    z-index: 1;
}

@media only screen and (max-width: 600px) {
    .gameArea{
        width: 300px;
        height: 300px;
    }
}