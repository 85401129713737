.copyPassword{
    display: block;
    text-align: center;
    margin: var(--bottom-spacing) 0;
    padding: var(--padding);
    background-color: var(--dark-bg);
    border-radius: var(--border-radius);
    word-wrap: break-word;
}
.copyPassword > p {
    font-size: 1.5rem;
    color: rgb(94, 94, 94);
    margin-bottom: var(--bottom-spacing);
}