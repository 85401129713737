.contactFormContainer{
    width: 100%;
    max-width: 500px;
    box-sizing: border-box;
}
.inlineInputs{
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: auto auto;
}
@media only screen and (max-width: 768px) {
    .inlineInputs{
        grid-template-columns: auto;
    }
}