.percResult div{
    display: inline-block;
    font-size: 4rem;
    width: 40%;
    margin: 40px 5% 10px 5%;
    text-align: center;
    border-bottom: 4px solid var(--orange);
    box-sizing: border-box;
}
.percResult span {
    display: inline-block;
    text-align: center;
    width: 40%;
    margin: 0 5% 40px 5%;
    font-size: 1.5rem;
    padding-bottom: var(--bottom-spacing);
    box-sizing: border-box;
}