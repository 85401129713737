:root {
  --dark-bg: #161616;
  --semidark-bg: #242424;
  --light-bg: #f0f0f0;
  --white: white;
  --accent: #11E8FC;
  --danger: #FF0000;
  --orange: #FE6906;
  --bottom-spacing: 3rem;
  --padding: 2rem;
  --border: 1px solid var(--accent);
  --border-radius: 4px;
  --hover-btn-shadow: 0 0 6px var(--accent);
}
*{margin: 0; padding: 0;}

html{font-size: 8px;}
body{
  background-color: var(--semidark-bg); 
  color: var(--white);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  
}

h1, h2, h3{
  color: white;
  font-size: 6rem;
  font-weight: 700;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: var(--bottom-spacing);
}
h2{
  font-size: 3rem;
  font-weight: 700;
}
h3{
  font-size: 2rem;
  font-weight: normal;
}
button{
  min-width: 200px;
  padding: 2rem;
  border: 1px solid #00FFFF;
  border-radius: 4px;
  text-align: center;
  color: #00FFFF;
  font-size: 2.5rem;
  background-color: transparent;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: normal;
  cursor: pointer;
}
button:hover{
  box-shadow: var(--hover-btn-shadow);
}
button.small-btn {
  min-width: initial;
}
button.icon-button{
  border: none;
  padding: 0;
  min-width: initial;
  cursor: pointer;
}
button.icon-button:hover{
  box-shadow: none;
}
a {text-decoration: none;}

.text-to-center{
  text-align: center;
}
.max-5 {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

input, textarea{
  width: 100%;
  width: -moz-available;
  background-color: var(--semidark-bg);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: var(--padding);
  color: var(--accent);
  margin-bottom: var(--bottom-spacing);
  font-size: 2rem;
}
input:active, textarea:active{
  box-shadow: var(--hover-btn-shadow);
}
label {
  color: var(--white);
  font-size: 2.5rem;
  display: block;
}
input[type='checkbox']{
  display: inline-block;
  width: 40px;
  height: 2.5rem;
}
input[type='checkbox']:checked:before{
  background-color: var(--accent);
}
input[type='number'] {
  text-align: center;
}
ul {
  list-style: none;
}

@media only screen and (max-width: 768px) {
  html{font-size: 6px;}
}

