.notesContainer {
    max-width: 1088px;
    margin: auto;
    padding: var(--padding);
    min-height: 100vh;
}
.notesHeader {
    display: flex;
    justify-content: space-between;
    background-color: var(--secondary-light);
    border: var(--border);
    border-radius: var(--border-radius);
    align-items: center;
    margin-bottom: var(--bottom-spacing);
}
.notesHeader button {
    background-color: var(--primary);
    padding: var(--padding);
    height: fit-content;
    border-radius: var(--border-radius-big);
}
.search {
    display: flex;
    padding: var(--padding);    
}
.search input {
    border: none;
    font-size: 2rem;
    background-color: var(--secondary-light);
    margin-bottom: 0;
}
.search input:focus {
    outline: none;
}
.notesList{
    display: grid;
    grid-gap: var(--padding);
    grid-template-columns: repeat(auto-fit, minmax(205px, 1fr));
}
.note {
    font-size: 2rem;
    border: var(--border);
    border-radius: var(--border-radius);
    padding: var(--padding);
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    white-space: pre-wrap;  /* keep all spaces in text and show them */
}
.note.new {
    background-color: var(--secondary);
}
.addNoteTextarea {
    border: none;
    resize: none;
    background-color: var(--secondary);
    font-size: 2rem;
}
.addNoteTextarea:focus {
    outline: none;
}
.noteFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.save {
    background-color: var(--primary);
    border-radius: 1rem;
    padding: 1rem;
}
.save:hover {
    cursor: pointer;
    background-color: var(--primary-dark);
}