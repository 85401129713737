.mainContainer {
    padding: 80px;
    margin-left: 160px;
}

@media only screen and (max-width: 768px) {
    .mainContainer {
        padding: var(--padding);
        margin: 60px 0 0 0;
    }
}