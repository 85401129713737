.budgetContainer {
    display: flex;
    flex-direction: column;
    padding: var(--padding);
    font-size: 2.5rem;
}
.budgetContainer h1, .budgetContainer h3 {
    text-align: center;
}
.budgetContainer h3 {
    font-size: 4rem;
}
.moneyContainer {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.showMoney {
    display: flex;
    flex: 1 1 200px;
    padding: var(--padding);
    border-radius: var(--border-radius);
    color: var(--white);
    justify-content: space-between;
    align-items: center;
}
.showMoney span{
    font-size: 3rem;
}
.showMoney button, .budgetForm button {
    border-radius: var(--border-radius-small);
}
.expensesList{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.expensesList input {
    border: var(--border);
    border-radius: var(--border-radius);
    outline: none;
    padding: var(--padding);
    font-size: 2rem;
    text-align: center;
}
.expensesList ul{
    display: flex;
    padding-left: 0;
    flex-direction: column;
    list-style: none;
}
.expensesList ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: var(--border);
    border-radius: var(--border-radius);
    padding: var(--padding);
    margin-bottom: var(--bottom-spacing);
}
.budgetListButtons {
    display: flex;
    justify-content: center;
    column-gap: var(--padding);
}
.budgetForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    align-self: center;
    row-gap: var(--padding);
}
.formgroup {
    display: flex;
    flex-wrap: wrap;
    column-gap: var(--padding);
}
.inputgroup {
    display: flex;
    flex-direction: column;
    flex: 1 200;
    text-align: center;
}
.inputgroup label {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: var(--bottom-spacing);
}
.inputgroup input {
    padding: var(--padding);
    font-size: 2.5rem;
    text-align: center;
}
.alert {
    background-color: var(--orange);
}