.morse-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.morse-btn-container {
    display: flex;
    justify-content: space-between;
}
.morse-button {
    width: 12rem;
    height: 12rem;
    color: var(--white);
    background-color: var(--orange);
    border: 2px solid var(--white);
    border-radius: 50%;
    font-size: 6rem;
    font-weight: 700;
    min-width: 0 !important;
    margin: var(--bottom-spacing);
    padding-top: 0;
}
.morse-space-btn {
    font-size: 3rem;
    font-weight: 500;
    padding-top: var(--padding);
    background-color: unset;
}
.center-btn {
    margin:auto;
    display: flex;
    justify-content: center;
}
