.quizContainer {
    background-color: var(--primary);
    max-width: 500px;
    min-height: 200px;
    height: min-content;
    border-radius: var(--border-radius);
    border: var(--border);
    padding: var(--padding);
    margin: 20vh auto;
    display: flex;
    justify-content: space-evenly;
    color: white;
}
.scoreSection {
    display: flex;
    font-size: 3rem;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}
.questionSection {
    width: 100%;
    position: relative;
}
.questionCount {
    font-size: 1.8rem;
    margin-bottom: 2.4rem;
}
.questionCount span {
    font-size: 4rem;
}
.questionText {
    font-size: 2.5rem;
}

.answerSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.answerSection button, .scoreSection button {
    width: 100%;
    font-size: 3rem;
    color: var(--white);
    border-radius: var(--border-radius);
    display: flex;
    padding: var(--padding);
    margin-bottom: var(--bottom-spacing);
    justify-content: flex-start;
    align-items: center;
    border: 2px solid var(--accent);
}
.scoreSection button{
    justify-content: center;
}