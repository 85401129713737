.navbar {
    width: 160px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--dark-bg);
    color: var(--white);
}
.navList {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: center;
    height: 100vh;
}
.navLinkItem {
    color: var(--white);
    text-decoration: none;
    text-align: center;
    padding: 10px 8px;
    display: block;
    font-size: 3rem;
}

.navLinkItem:hover,
.is-active-nav-link {
    color: var(--accent);
}

@media only screen and (max-width: 768px) {
    .navbar {
        width: 100%;
        height: 60px;
        position: sticky;
    }
    .navList {
        flex-direction: row;
        height: initial;
    }
    .navLinkItem {
       /* display: inline-block ; */
        font-size: 3rem;
    }
}