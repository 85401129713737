.cardContainer{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px,1fr));
    grid-gap: 3rem;
}
.card{
    border: var(--border);
    border-radius: var(--border-radius);
    color: var(--white); 
    margin-bottom: var(--bottom-spacing);
    height: 100%;
}
.card img{
    width: 100%;
    height: 400px;
    object-fit: contain;
}
.card h2{
    text-align: center;
    padding: 0 var(--padding);
}
.card p{
    font-size: 2.5rem;
    padding: var(--padding);
}