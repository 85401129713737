.checkContent {
    display: flex;
    align-items: center;
    margin: auto;
    flex-direction: column;
    max-width: 550px;    
}
.checkContent h3 {
    font-size: 3rem;
    text-align: center;
}
.checkContent input {
    font-size: 3rem;
    text-align: center;
    color: var(--white);
}
.cvContainer {
    display: flex;
    flex-direction: column;
}
.cvContainer > h2 {
    font-size: 4rem;
    font-weight: 600;
}
.cvContainer div {
    margin-bottom: var(--bottom-spacing);
}
.showControl {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
}
.showControl h2 {
    margin-bottom: unset;
}
.showControl > span {
    color: var(--orange);
}
.profil {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
}
.profilImg {
    width: 25rem;
    height: 25rem;
    border-radius: 50%;
    object-position: top ;
    object-fit: cover;
}
.profilName {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 250px;
}
.contactInfo {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
.contactInfo div {
    padding: 1rem 2rem;
    font-size: 2rem;
}
.summary, .educationContainer, .skillsContainer, .workExperienceContainer {
    font-size: 2rem;
    border: var(--border);
    border-radius: var(--border-radius);
    padding: var(--padding);
    transition: height 0.3s ease;
}
.experienceHeader {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.experienceHeader h3 {
    font-size: 2.5rem;
    font-weight: 600;
}
.experienceContainer h4 {
    white-space: pre-wrap;
    font-weight: 400;
}
.educationContainer {
    font-size: 2rem;
    font-weight: 600;
}
.educationContainer span{
    font-weight: 400;
}
.skillsContainer {
    font-size: 2rem;

}
.skillItem {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.skillItem p {
    padding-left: 1rem;
}
@media only screen and (max-width: 424px) {
    .profilImg {
      margin: auto;
    }
}