.termostatContainer {
    width: 100%;
    max-width: 300px;
    height: 400px;
    background-color: var(--semidark-bg);
    border: var(--border);
    border-radius: var(--border-radius);
    padding: var(--padding);
    margin: 20vh auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.temperature {
    width: 240px;
    height: 240px;
    font-size: 2rem;
    border-radius: 50%;
    border: 4px solid var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    margin-bottom: var(--bottom-spacing);
}
.heat {
    background-color: brown;
}
.cool {
    background-color: rgb(48, 50, 155);
}
.temp {
    font-size: 5rem;
    font-weight: 600;
}
.controls {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}
.controls button {
    min-width: inherit;
    padding: 0px;
    width: 6rem;
    height: 6rem;
    background-color: var(--secondary-dark);
    font-weight: 200;
    font-size: 4.4rem;
    border-radius: 50%;
    border: 2px solid white;
    color: var(--white);
}
.termostatContainer input {
    background-color: var(--primary);
    border: none;
    color: white;
    width: 30%;
    text-align: center;
    font-size: 3.6rem;
}