.todoList{
    color: var(--white);
    font-size: 2.5rem;
}
.todoListItem{
    border: var(--border);
    border-radius: var(--border-radius);
    padding: var(--padding);
    margin-bottom: var(--bottom-spacing);
    cursor: pointer;
}
.todoListItem div {
    float: inline-start;
}
.todoCompleted {
    border: 1px solid var(--orange);
}
.todoItemDescription{
    font-size: 1.5rem;
}
.removeTodoItem {
    float: inline-end;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    cursor: pointer;
}

.addTodoForm h3 {
    font-size: 2.5rem;
    margin-bottom: var(--bottom-spacing);
}
.addTodoForm form div {
    display: flex;
    justify-content: center;
}