.calculatorContainer {
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
}
.calculator {
    width: 100%;
    max-width: 400px;
    background-color: var(--semidark-bg);
    border-radius: 16px;
    overflow: hidden;
    box-shadow: var(--hover-btn-shadow);
} 
.display {
    padding: 16px;
    text-align: right;
    background-color: var(--semidark-bg);
    color: var(--white);
    font-size: 24px;
    font-weight: 300;
    border-bottom: 1px solid black;
}
.display span {
    font-size: 14px;
    color: #888;
}
.calculatorContainer button {
    appearance: none;
    border: none;
    outline: none;
    font-size: 20px;
    padding: 16px;
    cursor: pointer;
    transition: 0.4s;
    min-width: initial;
}
.calculatorContainer button:hover {
    opacity: 0.9;
}
.operators {
    display: flex;
}
.operatorsBtn {
    flex: 1 1 0%;
    background-color: var(--semidark-bg);
    font-weight: 700;
    color: var(--orange);
}
.operatorsBtn:hover {
    border: none;
    border: 1px solid var(--orange);
    box-shadow: 0 0 0 var(--orange);
}
.digits {
    display: flex;
    flex-wrap: wrap;
}
.digits button {
    flex: 1 1 33.333%;
    max-width: 33.333%;
    background-color: var(--semidark-bg);
}
.digits button:hover {
    border: 1px solid var(--semidark-bg);
    box-shadow: 0 0 0 var(--semidark-bg);
}